<template>
	<div class="ClueSelectPolice">
		<w-navTab titleText="公安局"></w-navTab>
		<div class="container">
			<div class="head-title">
				全部公安局
			</div>
			<div class="list-item" v-for="(item, index) in typeList" @click="onTypeList(item, index)">
				<div class="item-left-title">{{ item.name }}</div>
				<van-icon style="margin-left: 12px;" name="success" color="#3377FF" size="20" v-if="active == index" />
			</div>
			
			<div class="footer"><div class="footer-button" @click="goBack()">确定</div></div>
		</div>
	</div>
</template>

<script>
import ClueReport from '@/api/clueReport';
import Vant from '@/vendor/vant';
import Event from '@/store/event';
export default {
	name: 'ClueSelectPolice',
	data() {
		return {
			active: 0,
			typeList: [],
		};
	},
	created() {
		ClueReport.clueoOrgs()
			.then(result=>{
				this.typeList = result.data
			},error=>{
				Vant.Toast.fail(error.msg);
			})
	},
	methods: {
		onTypeList(item, index) {
			this.active = index;
		},
		goBack(){
			this.$router.go(-1)
			Event.$emit('onCluePolice',this.typeList[this.active]);
		},
	}
};
</script>

<style scoped lang="less">
.ClueSelectPolice {
	background: #F5F6F8;
	min-height: 100vh;
	padding-bottom: 60px;
	box-sizing: border-box;
	.container{
		padding: 8px;
		box-sizing: border-box;
		.head-title {
			margin-bottom: 12px;
			margin-top: 4px;
			font-weight: bold;
			font-size: 17px;
			line-height: 20px;
			color: #1C1D1D;
		}
		.list-item {
			background: #fff;
			border-radius: 2px;
			padding: 8px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 8px;
			&.list-item-body {
				margin-bottom: 1px !important;
			}
				.item-left-title {
					font-size: 15px;
					line-height: 22px;
					color: #1c1d1d;
					margin-bottom: 1px;
				}
			
		}
		.footer {
			position: fixed;
			bottom: 12px;
			left: 0;
			padding: 0 10px;
			width: 100%;
			box-sizing: border-box;
			.footer-button {
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				color: #fff;
				padding: 11px 0;
				background: linear-gradient(92.48deg, #3377FF 0%, #3377FF 100%);
				box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
				border-radius: 4px;
			}
		}
	}
	
}
</style>
